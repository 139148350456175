<template>
    <div id="page-user-list">
        <vx-card>
            <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full">
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="mt-2">SW/HW</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">                        
                            <v-select :options="types" :clearable="false" v-model="type" v-validate="'required'" name="type" placeholder="SW/HW"></v-select>
                            <span class="text-danger text-sm"  v-show="errors.has('product')">{{ errors.first('product') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="mt-2">Product</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">                        
                            <v-select :options="products" :clearable="false" v-model="product" v-validate="'required'" placeholder="Product"></v-select>
                            <span class="text-danger text-sm"  v-show="errors.has('product')">{{ errors.first('product') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="mt-2">Originator</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">                        
                            <vs-input class="w-full" v-model="originator" name="originator" v-validate="'required'" placeholder="Originator"/>
                            <span class="text-danger text-sm"  v-show="errors.has('originator')">{{ errors.first('originator') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="mt-2">Subject</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">                        
                            <vs-input class="w-full" v-model="subject" name="subject" v-validate="'required'" placeholder="Subject"/>
                            <span class="text-danger text-sm"  v-show="errors.has('subject')">{{ errors.first('subject') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="mt-2">PIC</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">                        
                            <h5 class="mt-2">{{ user.employee.name }}</h5>
                        </div>
                    </div>
                </div>
                <div class="vx-col md:w-1/2 w-full">
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="mt-2">Customer</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">                        
                            <v-select :options="customers" :clearable="false" v-model="customer" v-validate="'required'" placeholder="Customer"></v-select>
                            <span class="text-danger text-sm"  v-show="errors.has('customer')">{{ errors.first('customer') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="mt-2">Email</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                            <vs-input class="w-full" v-model="email" name="email" v-validate="'required'" placeholder="Email"/>
                            <span class="text-danger text-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="mt-2">Priority</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                            <v-select :options="priorities" :clearable="false" v-model="priority" v-validate="'required'" placeholder="Priority"></v-select>
                            <span class="text-danger text-sm"  v-show="errors.has('priority')">{{ errors.first('priority') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="mt-2">Deadline</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                            <div class="flex ">
                                <vs-input v-model="deadline_num" @keyup="setDeadline" placeholder="Number" name="deadline_num"/>
                                <span class="mt-2 mx-4">Days</span>
                                <flat-pickr 
                                    class="w-full"
                                    :config="configDateTimePicker"
                                    v-model="deadline"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="mt-2">Date</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full pt-2">
                            <span>{{ timestamp }}</span>
                        </div>
                    </div>
                </div>
            </div>  
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-2/12 w-full flex justify-end">
                    <span class="mt-2">Description</span>
                </div>
                <div class="vx-col sm:w-10/12 w-full">
                    <vs-textarea class="w-full" v-model="description" name="description" v-validate="'required'" height="100px"/>
                    <span class="text-danger text-sm"  v-show="errors.has('description')">{{ errors.first('description') }}</span>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col sm:w-10/12 w-full ml-auto">
                    <vs-button class="mr-3 mb-2" @click="saveData">Submit</vs-button>
                    <vs-button color="warning" type="border" class="mb-2" @click="input5 = input6 = input7 = input8 = ''; check2 = false;">Reset</vs-button>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
import moment from 'moment'
import flatPickr from "vue-flatpickr-component"
import "flatpickr/dist/flatpickr.css"

export default {
    components: {
        flatPickr
    },
    data() {
        return {
            timestamp: "",
            originator: null,
            subject: null,
            email: null,
            deadline_num: null,
            deadline: null,
            description: null,
            priority: {
                label: "", 
                value: ""
            },
            type: { 
                label: "", 
                value: "" 
            },
            product: { 
                label: "", 
                value: "" 
            },
            customer: { 
                label: "", 
                value: "" 
            },

            // Options
            types: [
                {
                    label: "Software",
                    value: 1
                },
                {
                    label: "Hardware",
                    value: 2
                }
            ],
            priorities: [
                {
                    label: "Critical",
                    value: "Critical"
                },
                {
                    label: "Emergency",
                    value: "Emergency"
                },
                {
                    label: "Major",
                    value: "Major"
                },
                {
                    label: "Minor",
                    value: "Minor"
                },
                {
                    label: "Question",
                    value: "Question"
                }
            ],
            products: [],
            customers: [],

            // Config
            configDateTimePicker: {
                enableTime: true,
                time_24hr: true,
                dateFormat: "d-m-Y H:i"
            }
        }
    },
    watch: {
        priority (priority) {
            // Auto set deadline for Emergency priority
            if (priority.value === 'Emergency') {
                this.deadline = moment().add(4, 'hours').format('DD-MM-YYYY HH:mm:ss')
                this.deadline_num = null
            }  
            else if (priority.value === 'Critical') {
                this.deadline = moment().add(1, 'days').format('DD-MM-YYYY HH:mm:ss')
                this.deadline_num = 1
            }
            else if (priority.value === 'Major') {
                this.deadline = moment().add(5, 'days').format('DD-MM-YYYY HH:mm:ss')
                this.deadline_num = 5
            }
            else if (priority.value === 'Minor') {
                this.deadline = moment().add(10, 'days').format('DD-MM-YYYY HH:mm:ss')
                this.deadline_num = 10
            }
            else if (priority.value === 'Question'){
                this.deadline = moment().add(30, 'days').format('DD-MM-YYYY HH:mm:ss')
                this.deadline_num = 30
            }
        }
    },
    computed: {
        user () {
            return this.$store.state.AppActiveUser;
        },
        validateForm () {
            return !this.errors.any() &&
            this.type !== null &&
            this.product !== null &&
            this.originator !== null  &&
            this.subject !== null  &&
            this.customer !== null  &&
            this.email !== null  &&
            this.priority !== null  &&
            this.description !== null
        }
    },
    methods : {
        setDeadline () {
            this.deadline = moment().add(this.deadline_num, 'days').format('DD-MM-YYYY HH:mm:ss')
        },
        getNow () {
            const today = new Date();
            const date = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date +' '+ time;
            this.timestamp = dateTime
        },
        getProducts () {
            const self = this
            self.products = []

            this.$store.dispatch('product/fetchProducts')
            .then(res => {
                const data = res.data.data
                data.forEach(element => {
                    self.products.push({
                        label: element.name,
                        value: element.id
                    });
                });
            })
            .catch(err => {
                if (!err.response) {
                    // network error
                    err.response = {
                        data: {
                        message: 'Server error'
                        }
                    };
                }
                this.$vs.notify({
                title:'Opps something error',
                text: err.response.data.message,
                color:'danger'})
            })
        },
        getCustomers () {
            const self = this
            self.customers = []

            this.$store.dispatch('customer/fetchCustomers')
            .then(res => {
                const data = res.data.data
                data.forEach(element => {
                    self.customers.push({
                        label: element.company_name,
                        value: element.company_id
                    });
                });
                
            })
            .catch(err => {
                if (!err.response) {
                    // network error
                    err.response = {
                        data: {
                        message: 'Server error'
                        }
                    };
                }
                this.$vs.notify({
                title:'Opps something error',
                text: err.response.data.message,
                color:'danger'})
            })
        },
        saveData () {
            this.$validator.validateAll().then(result => {
                if (result) {  
                    const self = this
                    const deadlines = self.deadline.split(' ')
                    let companyId;

                    if(self.customer.value < 10) {
                        companyId = `0${self.customer.value}`
                    } else {
                        companyId = self.customer.value
                    }

                    const formData = new FormData()
                    formData.set('company_id', companyId)
                    formData.set('id', self.product.value)
                    formData.set('deviceType', self.type.value)
                    formData.set('subject', self.subject)
                    formData.set('short_desc', self.description)
                    formData.set('priority', self.priority.value)
                    formData.set('nama', self.originator)
                    formData.set('email', self.email)
                    formData.set('deadline', deadlines[0].split('-').reverse().join('-') + ' ' + deadlines[1] + ':00')
                    formData.set('pic', self.user.employee.name)

                    this.$store.dispatch('ticket/storeItem', {
                        body: formData
                    })
                    .then(res => {
                        self.$vs.notify({
                            color: 'success',
                            title: 'Success',
                            text: 'Trouble Ticket has been saved'
                        })
                        self.$router.push('/technical/ticketing/trouble-ticket/data')
                    })
                    .catch(err => {
                        if (!err.response) {
                            // network error
                            err.response = {
                                data: {
                                message: 'Server error'
                                }
                            };
                        }
                        this.$vs.notify({
                        title:'Opps something error',
                        text: err.response.data.message,
                        color:'danger'})
                    })
                }
            })
        },
    },
    created () {
        setInterval(this.getNow, 1000),
        this.getProducts()
        this.getCustomers()
    }
}
</script>
